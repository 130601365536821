import React from 'react';
import styles from "../DialPad.module.sass";


const Settings = () => {
    return (
        <div className={styles.settings}>
            Здесь будет выбор номера
        </div>
    );
};

export default Settings;