import React from 'react';
import cn from 'classnames';

const icons = {
  sun: 'M8.002 12a4 4 0 1 0 0-8 4 4 0 1 0 0 8zM7.999 1c.368 0 .667.298.667.667v.667a.67.67 0 0 1-.667.667c-.368 0-.667-.298-.667-.667v-.667c0-.368.298-.667.667-.667zm0 12c.368 0 .667.298.667.667v.665a.67.67 0 0 1-.667.667c-.368 0-.667-.298-.667-.667v-.665c0-.368.298-.667.667-.667zM1 7.999c0-.368.298-.667.667-.667h.667c.368 0 .667.298.667.667s-.298.667-.667.667h-.667c-.368 0-.667-.298-.667-.667zm12 0c0-.368.298-.667.667-.667h.664c.368 0 .667.298.667.667s-.298.667-.667.667h-.664c-.368 0-.667-.298-.667-.667zm-9.95 4.949a.67.67 0 0 1 0-.943l.471-.471a.67.67 0 0 1 .943 0c.261.26.26.682 0 .943l-.471.471a.67.67 0 0 1-.943 0zm8.485-8.485a.67.67 0 0 1 0-.943l.47-.47a.67.67 0 0 1 .943 0c.261.26.26.682 0 .943l-.47.47a.67.67 0 0 1-.943 0zM3.05 3.05a.67.67 0 0 1 .943 0l.471.471a.67.67 0 0 1 0 .943c-.26.261-.682.26-.943 0l-.471-.471a.67.67 0 0 1 0-.943zm8.485 8.485a.67.67 0 0 1 .943 0l.47.47a.67.67 0 0 1 0 .943c-.26.261-.682.26-.943 0l-.47-.47a.67.67 0 0 1 0-.943z',
  moon: 'M5.377 4.017c-1.235.841-2.044 2.257-2.044 3.861A4.67 4.67 0 0 0 8 12.544c1.603 0 3.02-.809 3.861-2.044a7.34 7.34 0 0 1-6.484-6.484zm7.29 5.194c.577 0 1.075.509.846 1.039-.92 2.134-3.042 3.628-5.513 3.628a6 6 0 0 1-6-6c0-2.471 1.494-4.593 3.628-5.513.53-.228 1.039.269 1.039.846a6 6 0 0 0 6 6z',
  'arrow-down':
    'M4.471 5.528c-.26-.26-.682-.26-.943 0s-.26.682 0 .943l.943-.943zM8 10l-.471.471c.26.26.682.26.943 0L8 10zm4.471-3.529c.26-.26.26-.682 0-.943s-.682-.26-.943 0l.943.943zm-8.943 0l4 4 .943-.943-4-4-.943.943zm4.943 4l4-4-.943-.943-4 4 .943.943z',
  'arrow-next':
    'M5.528 11.528c-.26.26-.26.682 0 .943s.682.26.943 0l-.943-.943zM10 8l.471.471c.26-.26.26-.682 0-.943L10 8zM6.471 3.528c-.26-.26-.682-.26-.943 0s-.26.682 0 .943l.943-.943zm0 8.943l4-4-.943-.943-4 4 .943.943zm4-4.943l-4-4-.943.943 4 4 .943-.943z',
  'arrow-left':
    'M7.138 11.862c.26.26.26.682 0 .943s-.682.26-.943 0L2.334 8.943c-.521-.521-.521-1.365 0-1.886l3.862-3.862c.26-.26.682-.26.943 0s.26.682 0 .943L3.943 7.333h9.39c.368 0 .667.298.667.667s-.298.667-.667.667h-9.39l3.195 3.195z',
  'arrow-right':
    'M8.862 11.862c-.26.26-.26.682 0 .943s.682.26.943 0l3.862-3.862c.521-.521.521-1.365 0-1.886L9.805 3.195c-.26-.26-.682-.26-.943 0s-.26.682 0 .943l3.195 3.195H2.667C2.298 7.333 2 7.632 2 8s.298.667.667.667h9.391l-3.195 3.195z',
  'arrow-bottom':
    'M8 1a1 1 0 0 1 .993.883L9 2v9.585l3.293-3.292.094-.081a1 1 0 0 1 1.32 1.495h0v-.001l-4.991 4.993c-.029.03-.061.059-.094.085l-.04.03-.062.041-.027.016-.078.04-.033.014-.067.025-.051.015-.06.015-.053.009-.063.008-.053.003L8 15l-.033-.001-.052-.003L8 15a1.01 1.01 0 0 1-.148-.011l-.054-.01-.058-.013-.052-.016-.069-.025-.033-.015-.07-.035-.039-.023-.063-.042a1.02 1.02 0 0 1-.12-.103l.09.08c-.035-.027-.068-.057-.099-.089L2.293 9.707l-.081-.088a1 1 0 0 1 .522-1.599 1 1 0 0 1 .973.273h0L7 11.585V2a1 1 0 0 1 1-1z',
  'arrow-top':
    'M8 1h.019l.07.004L8 1a1.01 1.01 0 0 1 .149.011l.053.01.061.014.05.015.069.025.032.014.079.04.027.016.063.041c.043.031.085.067.125.106l-.085-.076.085.076 5 5a1 1 0 0 1-1.414 1.414h0 0L9 4.413V14a1 1 0 0 1-1.993.117L7 14V4.413L3.707 7.707l-.088.081a1 1 0 0 1-1.599-.522 1 1 0 0 1 .273-.973h0l5-5 .082-.073.039-.03.062-.042.04-.024.069-.035.034-.015.067-.025.054-.016.056-.014.056-.01.059-.007.07-.004z',
  'arrows-up-down':
    'M11.5 2.666c.368 0 .667.298.667.667h0v8.057l1.529-1.529c.26-.26.682-.26.943 0s.26.682 0 .943h0L12.443 13c-.521.521-1.365.521-1.886 0h0l-2.195-2.195c-.26-.26-.26-.682 0-.943s.682-.26.943 0h0l1.529 1.529V3.333c0-.368.298-.667.667-.667zM5.776 3l2.195 2.195c.26.26.26.682 0 .943s-.682.26-.943 0L5.5 4.609v8.057c0 .368-.298.667-.667.667s-.667-.298-.667-.667V4.609L2.638 6.138c-.26.26-.682.26-.943 0s-.26-.682 0-.943L3.891 3c.521-.521 1.365-.521 1.886 0z',
  store:
    'M11.631 1.333a2 2 0 0 1 1.864 1.275h0l1.192 3.065a1.57 1.57 0 0 1-.688 1.935h0v5.059a2 2 0 0 1-2 2h0-8a2 2 0 0 1-2-2h0V7.608a1.57 1.57 0 0 1-.688-1.935h0l1.192-3.065a2 2 0 0 1 1.864-1.275h0zm-6.965 6h-.001l-.467.234c-.274.137-.567.223-.866.258v4.841c0 .368.298.667.667.667h1.333v-2a2 2 0 0 1 2-2h1.333a2 2 0 0 1 2 2v2h1.333c.368 0 .667-.299.667-.667V7.825c-.299-.035-.592-.122-.866-.258l-.468-.234-.428.171c-.795.318-1.681.318-2.476 0l-.429-.171-.429.171c-.795.318-1.681.318-2.476 0l-.428-.171zm4 3.333H7.333c-.368 0-.667.298-.667.667h0v2h2.667v-2c0-.368-.298-.667-.667-.667h0zm2.965-8h-.474l.741 3.459.03.015h0l.468.234c.274.137.588.171.885.097.138-.035.215-.182.163-.315h0l-1.192-3.065c-.1-.256-.346-.425-.621-.425h0zm-6.79 0h-.474c-.275 0-.522.169-.621.425L2.554 6.157c-.052.133.025.28.163.315.297.074.611.04.885-.097l.468-.234.03-.015.741-3.459zm4.952 0H8.666v3.497l.257.103a2 2 0 0 0 1.486 0l.144-.058-.759-3.543zm-2.461 0H6.205l-.759 3.543.144.058a2 2 0 0 0 1.486 0l.257-.103V2.666z',
  'profile-circle':
    'M8 1.333A6.67 6.67 0 0 1 14.666 8a6.65 6.65 0 0 1-2.294 5.033l-.132.112A6.64 6.64 0 0 1 8 14.666a6.64 6.64 0 0 1-4.373-1.634A6.65 6.65 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 10a3.33 3.33 0 0 0-2.642 1.301c.731.418 1.57.667 2.465.697l.16.003h0H8l.172-.003c.897-.028 1.738-.278 2.47-.697A3.33 3.33 0 0 0 8 11.333zm0-8.667C5.054 2.666 2.666 5.054 2.666 8c0 1.505.623 2.864 1.625 3.833A4.66 4.66 0 0 1 8 10a4.66 4.66 0 0 1 3.708 1.833c1.002-.97 1.625-2.329 1.625-3.833 0-2.946-2.388-5.333-5.333-5.333zM8 4c1.473 0 2.667 1.194 2.667 2.667S9.472 9.333 8 9.333 5.333 8.139 5.333 6.666 6.527 4 8 4zm0 1.333c-.736 0-1.333.597-1.333 1.333S7.263 8 8 8s1.333-.597 1.333-1.333S8.736 5.333 8 5.333z',
  lightning:
    'M10.44 3.711l-.78 2.194h2.443c1.743 0 2.652 2.073 1.471 3.355l-4.657 5.054c-1.475 1.601-4.085.026-3.355-2.025l.78-2.194H3.899c-1.743 0-2.652-2.074-1.471-3.355l4.657-5.054C8.56.084 11.169 1.66 10.44 3.711zM8.065 2.589L3.409 7.644a.67.67 0 0 0 .49 1.118h3.387c.217 0 .42.105.545.282s.156.404.083.608l-1.097 3.084a.67.67 0 0 0 1.118.675l4.656-5.054a.67.67 0 0 0-.49-1.118H8.715c-.217 0-.42-.105-.545-.282s-.156-.404-.083-.608l1.097-3.084a.67.67 0 0 0-1.118-.675z',
  plus: 'M8 0a1.6 1.6 0 0 1 1.589 1.413L9.6 1.6v4.8h4.8a1.6 1.6 0 0 1 .187 3.189L14.4 9.6H9.6v4.8a1.6 1.6 0 0 1-3.189.187L6.4 14.4V9.6H1.6a1.6 1.6 0 0 1-.187-3.189L1.6 6.4h4.8V1.6A1.6 1.6 0 0 1 8 0z',
  'plus-circle':
    'M8 1.333A6.67 6.67 0 0 1 14.666 8 6.67 6.67 0 0 1 8 14.666 6.67 6.67 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 1.333C5.054 2.666 2.666 5.054 2.666 8S5.054 13.333 8 13.333 13.333 10.945 13.333 8 10.945 2.666 8 2.666zm0 2c.368 0 .667.298.667.667h0v2h2c.368 0 .667.298.667.667s-.298.667-.667.667h0-2v2c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0v-2h-2c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h0 2v-2c0-.368.298-.667.667-.667z',
  'pie-chart':
    'M6.666 4c.368 0 .667.298.667.667v4h4c.368 0 .667.298.667.667 0 1.055-.313 2.086-.899 2.963s-1.419 1.561-2.394 1.964-2.047.509-3.081.304-1.985-.714-2.731-1.46-1.254-1.696-1.46-2.731-.1-2.107.304-3.081 1.087-1.807 1.964-2.394S5.611 4 6.666 4zM6 5.389a4 4 0 0 0-3.029 2.413 4 4 0 0 0 .867 4.359 4 4 0 0 0 4.359.867A4 4 0 0 0 10.61 10H6.666c-.177 0-.346-.07-.471-.195S6 9.51 6 9.333V5.389zm3.333-4.056c1.414 0 2.771.562 3.771 1.562s1.562 2.357 1.562 3.771c0 .368-.298.667-.667.667H9.333c-.177 0-.346-.07-.471-.195s-.195-.295-.195-.471V2c0-.177.07-.346.195-.471s.295-.195.471-.195zM10 2.722V6h3.277A4 4 0 0 0 10 2.722z',
  home: 'M9.338 1.87l4.667 4.2a2 2 0 0 1 .662 1.487v5.109a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V7.557a2 2 0 0 1 .662-1.487l4.667-4.2a2 2 0 0 1 2.676 0zm-.892.991c-.254-.228-.638-.228-.892 0h0l-4.667 4.2c-.14.126-.221.307-.221.496h0v5.109c0 .368.298.667.667.667h0 2V10a2 2 0 0 1 2-2h0 1.333a2 2 0 0 1 2 2h0v3.333h2c.368 0 .667-.298.667-.667h0V7.557c0-.189-.08-.369-.221-.496h0zm.221 6.471H7.333c-.368 0-.667.299-.667.667h0v3.333h2.667V10c0-.368-.298-.667-.667-.667h0z',
  diamond:
    'M11.213 1.333a2 2 0 0 1 1.609.812h0l1.951 2.644a2 2 0 0 1-.022 2.405h0l-5.164 6.736a2 2 0 0 1-3.174 0h0L1.249 7.194a2 2 0 0 1-.022-2.405h0l1.951-2.644a2 2 0 0 1 1.609-.812h0zm2.263 5.333H2.525l4.946 6.452c.267.348.791.348 1.058 0l4.946-6.452zm-2.263-4H4.787c-.212 0-.411.1-.536.271h0L2.483 5.333h11.035l-1.768-2.396c-.126-.17-.325-.271-.536-.271h0z',
  promotion:
    'M9.297 1.563l.848.722c.107.091.24.146.379.157l1.11.089a2 2 0 0 1 1.835 1.835l.089 1.11c.011.14.066.273.157.379l.722.848a2 2 0 0 1 0 2.594l-.722.848c-.091.107-.146.24-.157.379l-.089 1.11a2 2 0 0 1-1.835 1.835l-1.11.089c-.14.011-.273.066-.379.157l-.848.722a2 2 0 0 1-2.594 0l-.848-.722c-.107-.091-.24-.146-.379-.157l-1.11-.089a2 2 0 0 1-1.835-1.835l-.089-1.11c-.011-.14-.066-.273-.157-.379l-.722-.848a2 2 0 0 1 0-2.594l.722-.848c.091-.107.146-.24.157-.379l.089-1.11a2 2 0 0 1 1.835-1.835l1.11-.089c.14-.011.273-.066.379-.157l.848-.722a2 2 0 0 1 2.594 0zm-1.73 1.015L6.72 3.3a2 2 0 0 1-1.138.471l-1.11.089c-.326.026-.585.285-.612.612l-.089 1.11A2 2 0 0 1 3.3 6.72l-.722.848c-.212.249-.212.616 0 .865l.722.848a2 2 0 0 1 .471 1.138l.089 1.11c.026.326.285.586.612.612l1.11.089a2 2 0 0 1 1.138.471l.848.722c.249.212.616.212.865 0l.848-.722a2 2 0 0 1 1.138-.471l1.11-.089c.326-.026.585-.285.611-.612l.089-1.11A2 2 0 0 1 12.7 9.28l.722-.848c.212-.249.212-.616 0-.865L12.7 6.72a2 2 0 0 1-.471-1.138l-.089-1.11c-.026-.326-.285-.585-.611-.612l-1.11-.089A2 2 0 0 1 9.28 3.3l-.848-.722c-.249-.212-.616-.212-.865 0zm2.1 6.089a1 1 0 1 1 0 2 1 1 0 1 1 0-2zm.427-3.179c.283.236.321.656.085.939l-3.333 4c-.236.283-.656.321-.939.085s-.321-.656-.085-.939l3.333-4c.236-.283.656-.321.939-.085zm-3.76-.155a1 1 0 1 1 0 2 1 1 0 1 1 0-2z',
  help: 'M8 1.333A6.67 6.67 0 0 1 14.666 8 6.67 6.67 0 0 1 8 14.666 6.67 6.67 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 1.333C5.054 2.666 2.666 5.054 2.666 8S5.054 13.333 8 13.333 13.333 10.945 13.333 8 10.945 2.666 8 2.666zm0 8.667c.368 0 .667.298.667.667s-.298.667-.667.667-.667-.298-.667-.667.298-.667.667-.667zM8 4c1.473 0 2.667 1.194 2.667 2.667a2.67 2.67 0 0 1-2 2.583V10c0 .368-.299.667-.667.667s-.667-.298-.667-.667V8.666C7.333 8.298 7.632 8 8 8c.736 0 1.333-.597 1.333-1.333S8.736 5.333 8 5.333c-.58 0-1.074.37-1.258.889-.123.347-.504.529-.851.406s-.529-.504-.406-.851C5.851 4.743 6.838 4 8 4z',
  search:
    'M7.333 1.333a6 6 0 0 1 6 6c0 1.417-.491 2.719-1.312 3.745h0l1.783 1.783c.26.26.26.682 0 .943s-.682.26-.943 0h0l-1.783-1.783c-1.026.821-2.328 1.312-3.745 1.312a6 6 0 1 1 0-12zm0 1.333a4.67 4.67 0 0 0-4.667 4.667A4.67 4.67 0 0 0 7.333 12 4.67 4.67 0 0 0 12 7.333a4.67 4.67 0 0 0-4.667-4.667z',
  'close-circle':
    'M8 14.666A6.67 6.67 0 0 0 14.666 8 6.67 6.67 0 0 0 8 1.333 6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.666zm-2.471-4.195c-.26-.26-.26-.682 0-.943L7.057 8 5.528 6.471c-.26-.26-.26-.682 0-.943s.682-.26.943 0L8 7.057l1.529-1.529c.26-.26.682-.26.943 0s.26.682 0 .943L8.942 8l1.529 1.529c.26.26.26.682 0 .943s-.682.26-.943 0L8 8.942l-1.529 1.529c-.26.26-.682.26-.943 0z',
  close:
    'M4.471 3.528c-.26-.26-.682-.26-.943 0s-.26.682 0 .943L7.057 8l-3.529 3.529c-.26.26-.26.682 0 .943s.682.26.943 0L8 8.942l3.529 3.529c.26.26.682.26.943 0s.26-.682 0-.943L8.942 8l3.529-3.529c.26-.26.26-.682 0-.943s-.682-.26-.943 0L8 7.057 4.471 3.528z',
  photos:
    'M12.666 1.333a2 2 0 0 1 2 2V10a2 2 0 0 1-2 2H12v.667a2 2 0 0 1-2 2h0-6.667a2 2 0 0 1-2-2h0V6a2 2 0 0 1 2-2h0H4v-.667a2 2 0 0 1 2-2h6.667zM4 5.333h-.667c-.368 0-.667.298-.667.667h0v6.667c0 .368.298.667.667.667h0H10c.368 0 .667-.298.667-.667h0V12H6a2 2 0 0 1-2-2V5.333zm2.893 1.278l-1.56 1.871V10c0 .368.298.667.667.667h5.636L7.896 6.587c-.273-.298-.745-.286-1.004.024zm5.774-3.945H6c-.335 0-.612.247-.659.568l-.007.099V6.4l.535-.642a2 2 0 0 1 2.896-.187l.115.116 4.251 4.638.066.08c.064-.084.109-.184.127-.293l.009-.111V3.333c0-.368-.298-.667-.667-.667zM11 4a1 1 0 1 1 0 2 1 1 0 1 1 0-2z',
  add: 'M8.667 3.334c0-.368-.298-.667-.667-.667s-.667.298-.667.667v4h-4c-.368 0-.667.298-.667.667s.298.667.667.667h4v4c0 .368.298.667.667.667s.667-.298.667-.667v-4h4c.368 0 .667-.298.667-.667s-.298-.667-.667-.667h-4v-4z',
  check:
    'M13.138 4.202c.26.26.26.682 0 .943l-5.724 5.724a2 2 0 0 1-2.828 0L2.195 8.478c-.26-.26-.26-.682 0-.943s.682-.26.943 0l2.391 2.391c.26.26.682.26.943 0l5.724-5.724c.26-.26.682-.26.943 0z',
  'check-circle':
    'M8 14.666A6.67 6.67 0 0 0 14.666 8a6.64 6.64 0 0 0-.745-3.066c-.1-.192-.356-.224-.509-.07L8.276 10c-.521.521-1.365.521-1.886 0L4.528 8.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0l1.862 1.862 5.348-5.344c.126-.126.131-.331.004-.456C11.481 2.067 9.826 1.333 8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.666z',
  'check-all':
    'M1.528 8.195c.26-.26.682-.26.943 0h0l1.724 1.724.009.009 1.325 1.325a2 2 0 0 1-2.277-.391h0L1.528 9.138c-.26-.26-.26-.682 0-.943zm12.943-3.367c.26.26.26.682 0 .943l-5.091 5.091a2 2 0 0 1-2.828 0L4.828 9.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0l1.724 1.724c.26.26.682.26.943 0l5.091-5.091c.26-.26.682-.26.943 0zm-4.276.033c.26-.26.682-.26.943 0s.26.682 0 .943h0L7.966 8.976l-.943-.943z',
  trash:
    'M6.666 6.673c.368 0 .667.298.667.667v3.333c0 .368-.298.667-.667.667S6 11.042 6 10.673V7.34c0-.368.298-.667.667-.667zm2.667 0c.368 0 .667.298.667.667v3.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667V7.34c0-.368.298-.667.667-.667zm0-5.333a2 2 0 0 1 2 2h0H14c.368 0 .667.298.667.667s-.298.667-.667.667h0-.667v8a2 2 0 0 1-2 2h0-6.667a2 2 0 0 1-2-2h0v-8H2c-.368 0-.667-.298-.667-.667S1.631 3.34 2 3.34h0 2.667a2 2 0 0 1 2-2h0zM12 4.673H4v8c0 .368.298.667.667.667h6.667c.368 0 .667-.298.667-.667v-8zm-2.667-2H6.666c-.368 0-.667.298-.667.667h4c0-.368-.298-.667-.667-.667z',
  message:
    'M4.666 6c0-.368.298-.667.667-.667h5.333c.368 0 .667.298.667.667s-.298.667-.667.667H5.333c-.368 0-.667-.298-.667-.667zm0 2.667c0-.368.298-.667.667-.667H8c.368 0 .667.298.667.667s-.298.667-.667.667H5.333c-.368 0-.667-.298-.667-.667zm8-6.667a2 2 0 0 1 2 2h0v6.667a2 2 0 0 1-2 2h0-7.333L3.52 14.178c-.868.724-2.187.106-2.187-1.024h0V4a2 2 0 0 1 2-2h0zm0 1.333H3.333c-.368 0-.667.298-.667.667v9.153l1.813-1.511c.24-.2.542-.309.854-.309h7.333c.368 0 .667-.298.667-.667V4c0-.368-.298-.667-.667-.667z',
  messages:
    'M12.593 1.526c.886-.591 2.073.044 2.073 1.109h0v6.175a2 2 0 0 1-2 2h0H12v.667a2 2 0 0 1-1.851 1.995l-.149.005H4.666l-1.167.934c-.839.672-2.067.123-2.161-.914l-.006-.127V7.478a2 2 0 0 1 1.851-1.995l.149-.005H4v-.667a2 2 0 0 1 2-2h0 4.667zM10 6.811H3.333c-.368 0-.667.298-.667.667v5.893l1.167-.934c.236-.189.53-.292.833-.292H10c.368 0 .667-.298.667-.667v-4c0-.368-.298-.667-.667-.667zm-1.333 2c.368 0 .667.298.667.667s-.298.667-.667.667h-4c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h4zm4.667-6.175L11.406 3.92c-.219.146-.476.224-.74.224h0H6c-.368 0-.667.298-.667.667h0v.667H10a2 2 0 0 1 1.995 1.851l.005.149v2h.667c.368 0 .667-.298.667-.667h0V2.636z',
  'more-horizontal':
    'M5.334 8.006c0 .736-.597 1.333-1.333 1.333s-1.333-.597-1.333-1.333S3.264 6.673 4 6.673s1.333.597 1.333 1.333zm4 0c0 .736-.597 1.333-1.333 1.333s-1.333-.597-1.333-1.333S7.264 6.673 8 6.673s1.333.597 1.333 1.333zm4 0c0 .736-.597 1.333-1.333 1.333s-1.333-.597-1.333-1.333.597-1.333 1.333-1.333 1.333.597 1.333 1.333z',
  notification:
    'M8.019 1.333c2.935 0 5.314 2.379 5.314 5.314v2.134c0 .354.14.693.391.943l.667.667c.177.177.276.417.276.667 0 .521-.422.943-.943.943h-3.057c0 1.473-1.194 2.667-2.667 2.667S5.333 13.472 5.333 12H2.269c-.517 0-.936-.419-.936-.936 0-.254.103-.497.286-.673l.686-.663c.261-.252.408-.6.407-.963l-.006-2.102c-.009-2.941 2.373-5.33 5.314-5.33zM9.333 12H6.666c0 .736.597 1.333 1.333 1.333S9.333 12.736 9.333 12zM8.019 2.666a3.98 3.98 0 0 0-3.98 3.992h0l.006 2.102c.002.716-.284 1.403-.793 1.906h0 9.529c-.5-.5-.781-1.178-.781-1.886h0V6.647a3.98 3.98 0 0 0-3.98-3.98z',
  'bar-chart':
    'M2 1.34c.368 0 .667.298.667.667v10.667c0 .368.298.667.667.667H14c.368 0 .667.298.667.667s-.298.667-.667.667H3.333a2 2 0 0 1-2-2V2.007c0-.368.298-.667.667-.667zm2.666 4c.368 0 .667.298.667.667v5.333c0 .368-.298.667-.667.667S4 11.708 4 11.34V6.007c0-.368.298-.667.667-.667zm2.667 4c.368 0 .667.298.667.667v1.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667v-1.333c0-.368.298-.667.667-.667zM10 2.673c.368 0 .667.298.667.667v8c0 .368-.298.667-.667.667s-.667-.298-.667-.667v-8c0-.368.298-.667.667-.667zm2.666 4c.368 0 .667.298.667.667v4c0 .368-.298.667-.667.667S12 11.708 12 11.34v-4c0-.368.298-.667.667-.667z',
  grid: 'M12.666 1.34a2 2 0 0 1 2 2h0v9.333a2 2 0 0 1-2 2h0-9.333a2 2 0 0 1-2-2h0V3.34a2 2 0 0 1 2-2h0zm-3.333 9.333H6.666v2.667h2.667v-2.667zm4 0h-2.667v2.667h2c.368 0 .667-.299.667-.667h0v-2zm-8 0H2.666v2c0 .368.298.667.667.667h0 2v-2.667zm4-4H6.666V9.34h2.667V6.673zm4 0h-2.667V9.34h2.667V6.673zm-8 0H2.666V9.34h2.667V6.673zm4-4H6.666V5.34h2.667V2.673zm3.333 0h-2V5.34h2.667v-2c0-.368-.298-.667-.667-.667zm-7.333 0h-2c-.368 0-.667.298-.667.667h0v2h2.667V2.673z',
  leaderboard:
    'M6 4.673c0-.736.597-1.333 1.333-1.333h1.333c.736 0 1.333.597 1.333 1.333v7.333c0 .368-.298.667-.667.667H6.666a.67.67 0 0 1-.667-.667V4.673zm5.333 4c0-.368.298-.667.667-.667h1.333c.736 0 1.333.597 1.333 1.333v2.667c0 .368-.298.667-.667.667h-2c-.368 0-.667-.298-.667-.667V8.673zm-10-.666c0-.736.597-1.333 1.333-1.333H4c.368 0 .667.298.667.667v4.667c0 .368-.298.667-.667.667H2c-.368 0-.667-.298-.667-.667v-4z',
  ticket:
    'M12.666 2.673a2 2 0 0 1 2 2h0v1.333c0 .368-.316.653-.639.83a1.33 1.33 0 0 0 0 2.34c.323.177.639.461.639.83h0v1.333a2 2 0 0 1-2 2h0-9.333a2 2 0 0 1-2-2h0v-1.333c0-.368.316-.653.639-.83a1.33 1.33 0 0 0 0-2.34c-.323-.177-.639-.461-.639-.83h0V4.673a2 2 0 0 1 2-2h0zm0 1.333H3.333c-.368 0-.667.298-.667.667v1.024C3.461 6.156 4 7.017 4 8.006a2.67 2.67 0 0 1-1.333 2.31v1.024c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667v-1.024C12.539 9.857 12 8.995 12 8.006a2.67 2.67 0 0 1 1.333-2.31V4.673c0-.368-.298-.667-.667-.667zM7.371 5.818a.67.67 0 0 1 1.259 0h0l.158.456a.67.67 0 0 0 .616.448h0l.482.01a.67.67 0 0 1 .389 1.198h0l-.385.292c-.222.168-.316.457-.235.724h0l.14.462a.67.67 0 0 1-1.019.74h0l-.396-.276a.67.67 0 0 0-.762 0h0l-.396.276a.67.67 0 0 1-1.019-.74h0l.14-.462c.081-.267-.013-.556-.235-.724h0l-.385-.292a.67.67 0 0 1 .389-1.198h0l.482-.01c.279-.006.525-.184.616-.448h0zM8 7.466a2 2 0 0 1-.514.374c.097.19.164.394.196.605a2 2 0 0 1 .636 0c.033-.211.099-.415.196-.605-.19-.096-.364-.223-.514-.374z',
  'shopping-bag':
    'M8 1.333c1.473 0 2.667 1.194 2.667 2.667h0 .901a2 2 0 0 1 1.985 1.752h0l.833 6.667a2 2 0 0 1-1.985 2.248h0-8.802a2 2 0 0 1-1.985-2.248h0l.833-6.667A2 2 0 0 1 4.433 4h0 .901c0-1.473 1.194-2.667 2.667-2.667zm-2.667 4h-.901c-.336 0-.62.25-.662.584h0l-.833 6.667c-.05.398.261.749.662.749h0 8.802c.401 0 .711-.351.662-.749h0l-.833-6.667c-.042-.334-.325-.584-.662-.584h0-.901V6c0 .368-.298.667-.667.667S9.334 6.368 9.334 6h0v-.667H6.667V6c0 .368-.298.667-.667.667S5.334 6.368 5.334 6h0v-.667zM8 2.666c-.736 0-1.333.597-1.333 1.333h2.667c0-.736-.597-1.333-1.333-1.333z',
  info: 'M8.001 1.334a6.67 6.67 0 0 1 6.667 6.667 6.67 6.67 0 0 1-6.667 6.667 6.67 6.67 0 0 1-6.667-6.667 6.67 6.67 0 0 1 6.667-6.667zm0 6c-.368 0-.667.298-.667.667h0v3.334c0 .368.298.667.667.667s.667-.298.667-.667h0V8.001c0-.368-.298-.667-.667-.667zm0-2.667c-.368 0-.667.298-.667.667s.298.667.667.667.667-.298.667-.667-.298-.667-.667-.667z',
  'info-stroke':
    'M8.003 1.334a6.67 6.67 0 0 1 6.667 6.667 6.67 6.67 0 0 1-6.667 6.667 6.67 6.67 0 0 1-6.667-6.667 6.67 6.67 0 0 1 6.667-6.667zm0 1.333c-2.945 0-5.333 2.388-5.333 5.333s2.388 5.333 5.333 5.333 5.333-2.388 5.333-5.333-2.388-5.333-5.333-5.333zm0 3.999c.368 0 .667.298.667.667v3.334c0 .368-.298.667-.667.667s-.667-.298-.667-.667V7.333c0-.368.298-.667.667-.667zm0-2c.368 0 .667.298.667.667S8.371 6 8.003 6s-.667-.298-.667-.667.298-.667.667-.667z',
  activity:
    'M6.326 3.369c-.075-.351-.577-.351-.652 0l-.796 3.716a2 2 0 0 1-1.956 1.581H2c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h.922c.314 0 .586-.22.652-.527l.796-3.716c.376-1.757 2.883-1.757 3.259 0l2.044 9.54c.075.351.577.351.652 0l.796-3.716a2 2 0 0 1 1.956-1.581H14c.368 0 .667.298.667.667s-.298.667-.667.667h-.922c-.314 0-.586.22-.652.527l-.796 3.716c-.376 1.757-2.883 1.757-3.259 0L6.326 3.369z',
  link: 'M1.333 8c0-1.841 1.492-3.333 3.333-3.333h2c.368 0 .667.298.667.667S7.035 6 6.666 6h-2a2 2 0 1 0 0 4h2c.368 0 .667.298.667.667s-.298.667-.667.667h-2c-1.841 0-3.333-1.492-3.333-3.333zm7.334-2.666c0-.368.298-.667.667-.667h2c1.841 0 3.333 1.492 3.333 3.333s-1.492 3.333-3.333 3.333h-2c-.368 0-.667-.298-.667-.667S8.966 10 9.334 10h2a2 2 0 1 0 0-4h-2c-.368 0-.667-.298-.667-.667zM5.333 8c0-.368.298-.667.667-.667h4c.368 0 .667.298.667.667s-.298.667-.667.667H6c-.368 0-.667-.298-.667-.667z',
  heart:
    'M7.867 3.475L8 3.611l.133-.136c1.495-1.523 3.923-1.523 5.418 0a3.93 3.93 0 0 1 0 5.485l-4.123 4.202a2 2 0 0 1-2.855 0L2.449 8.961a3.93 3.93 0 0 1 0-5.485c1.495-1.523 3.923-1.523 5.418 0zM3.4 4.409c-.979.998-.979 2.62 0 3.618l4.123 4.202c.261.266.69.266.952 0l4.123-4.202c.979-.998.979-2.62 0-3.618a2.45 2.45 0 0 0-3.514 0l-.609.621c-.125.128-.297.2-.476.2s-.35-.072-.476-.2l-.609-.621a2.45 2.45 0 0 0-3.514 0z',
  'heart-fill':
    'M7.867 3.475L8 3.611l.133-.136c1.495-1.523 3.923-1.523 5.418 0a3.93 3.93 0 0 1 0 5.485l-4.123 4.202a2 2 0 0 1-2.855 0L2.449 8.961a3.93 3.93 0 0 1 0-5.485c1.495-1.523 3.923-1.523 5.418 0z',
  basket:
    'M9.414 2.472l2.862 2.862h1.622c.406 0 .717.359.66.761l-.979 6.855a2 2 0 0 1-1.98 1.717H4.401a2 2 0 0 1-1.98-1.717l-.979-6.855c-.057-.402.254-.761.66-.761h1.622l2.862-2.862a2 2 0 0 1 2.828 0zm3.715 4.195H2.87l.871 6.094c.047.328.328.572.66.572h7.197c.332 0 .613-.244.66-.572l.871-6.094zM8.471 3.415a.67.67 0 0 0-.943 0h0L5.609 5.334h4.781zM5.333 8c.368 0 .667.298.667.667v2.667c0 .368-.298.667-.667.667s-.667-.298-.667-.667V8.667c0-.368.298-.667.667-.667zM8 8c.368 0 .667.298.667.667v2.667c0 .368-.298.667-.667.667s-.667-.298-.667-.667V8.667A.67.67 0 0 1 8 8zm2.667 0c.368 0 .667.298.667.667v2.667c0 .368-.298.667-.667.667S10 11.702 10 11.334V8.667c0-.368.298-.667.667-.667z',
  'video-recorder':
    'M9.333 3.333a2 2 0 0 1 2 2v.381l1.225-.875c.883-.63 2.108 0 2.108 1.085v4.151c0 1.085-1.226 1.715-2.108 1.085l-1.225-.875v.381a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2V5.333a2 2 0 0 1 2-2h6zm0 1.333h-6c-.368 0-.667.298-.667.667h0v5.333c0 .368.298.667.667.667h0 6c.368 0 .667-.298.667-.667h0V5.333c0-.368-.298-.667-.667-.667h0zm4 1.258l-2 1.429v1.294l2 1.429V5.924z',
  phone:
    'M3.526 2.121a1.55 1.55 0 0 1 2.094.385h0l1.005 1.371a2 2 0 0 1 .176 2.077h0l-.273.546c-.079.158-.092.341-.011.498.147.286.461.794 1.066 1.399s1.112.918 1.399 1.066c.157.081.34.068.498-.011h0l.546-.273a2 2 0 0 1 2.077.176h0l1.371 1.005a1.55 1.55 0 0 1 .385 2.094c-1.096 1.694-3.104 2.565-4.871 1.592-1.153-.635-2.54-1.6-3.998-3.057S2.568 8.146 1.933 6.992C.96 5.225 1.832 3.217 3.526 2.121zM4.25 3.24c-1.315.851-1.676 2.151-1.149 3.109.577 1.048 1.467 2.333 2.832 3.698s2.65 2.255 3.698 2.832c.958.528 2.258.166 3.109-1.149.063-.097.039-.226-.054-.295l-1.371-1.005c-.202-.148-.469-.17-.692-.059l-.546.273a1.88 1.88 0 0 1-1.705.004c-.403-.207-1.024-.601-1.731-1.309S5.539 8.012 5.331 7.608a1.88 1.88 0 0 1 .004-1.705l.273-.546c.112-.224.089-.491-.059-.692L4.544 3.295c-.068-.093-.198-.117-.295-.054z',
  design:
    'M3.757 1.872a2 2 0 0 1 2.828 0h0l1.9 1.899 1.69-1.69a2 2 0 0 1 2.828 0h0l.943.943a2 2 0 0 1 0 2.828h0l-1.69 1.69 1.872 1.872a2 2 0 0 1 0 2.828h0l-1.886 1.886a2 2 0 0 1-2.828 0h0l-1.872-1.872-1.467 1.467c-.093.093-.212.156-.341.182h0l-3.536.707c-.466.093-.878-.318-.784-.784h0l.707-3.536c.026-.129.089-.248.182-.341h0l1.467-1.467-1.899-1.9a2 2 0 0 1 0-2.828h0zm7.556 6.614l-2.828 2.828.458.458.471-.471c.26-.26.682-.26.943 0s.26.682 0 .943l-.471.471.471.471c.26.26.682.26.943 0l1.886-1.886c.26-.26.26-.682 0-.943l-1.872-1.872zM9.233 4.909L3.39 10.752l-.471 2.357 2.357-.471 5.843-5.843-1.886-1.886zM4.7 2.815L2.815 4.7c-.26.26-.26.682 0 .943l.471.471.943-.943c.26-.26.682-.26.943 0s.26.682 0 .943l-.943.943.485.485 2.828-2.828-1.9-1.9c-.26-.26-.682-.26-.943 0zm6.418.209l-.943.943 1.886 1.886.943-.943c.26-.26.26-.682 0-.943l-.943-.943c-.26-.26-.682-.26-.943 0z',
  multiselect:
    'M12.666 1.333a2 2 0 0 1 1.995 1.851l.005.149V10a2 2 0 0 1-1.851 1.995l-.149.005H12v.667a2 2 0 0 1-2 2h0-6.667a2 2 0 0 1-2-2h0V6a2 2 0 0 1 2-2h0H4v-.667A2 2 0 0 1 5.85 1.338L6 1.333h6.667zM4 5.333h-.667c-.368 0-.667.298-.667.667h0v6.667c0 .368.298.667.667.667h0H10c.368 0 .667-.298.667-.667h0V12H6a2 2 0 0 1-1.995-1.851L4 10V5.333zm8.667-2.667H6c-.368 0-.667.298-.667.667V10c0 .368.298.667.667.667h6.667c.368 0 .667-.298.667-.667V3.333c0-.368-.298-.667-.667-.667zm-.862 2.195c.26.26.26.682 0 .943L9.138 8.471c-.26.26-.682.26-.943 0L6.862 7.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0l.862.862 2.195-2.195c.26-.26.682-.26.943 0z',
  schedule:
    'M15 11a4 4 0 0 1-4 4 3.98 3.98 0 0 1-2.229-.678l.017.011H3a2 2 0 0 1-2-2h0v-8a2 2 0 0 1 2-2h0 .667v-.667c0-.368.298-.667.667-.667S5 1.298 5 1.667h0v.667h5.333v-.667c0-.368.298-.667.667-.667s.667.298.667.667h0v.667h.667a2 2 0 0 1 2 2h0v4.455h0l.022.034A3.98 3.98 0 0 1 15 11zm-4-2.667c-1.473 0-2.667 1.194-2.667 2.667S9.527 13.667 11 13.667s2.667-1.194 2.667-2.667S12.473 8.333 11 8.333zM3.667 3.667H3c-.368 0-.667.298-.667.667h0v8c0 .368.298.667.667.667h0 4.535C7.198 12.423 7 11.735 7 11a4 4 0 0 1 4-4 3.98 3.98 0 0 1 2.015.544L13 7.535V4.333c0-.368-.298-.667-.667-.667h0-.667v.667c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0v-.667H5v.667C5 4.702 4.702 5 4.333 5s-.667-.298-.667-.667h0v-.667zM11 9c.368 0 .667.298.667.667v1.057l.471.471c.26.26.26.682 0 .943s-.682.26-.943 0l-.667-.667c-.125-.125-.195-.295-.195-.471V9.667c0-.368.298-.667.667-.667z',
  facebook:
    'M8.5 4.667c0-.736.597-1.333 1.333-1.333h.667c.368 0 .667-.298.667-.667S10.868 2 10.5 2h-.667C8.36 2 7.166 3.194 7.166 4.667V6H5.5c-.368 0-.667.298-.667.667s.298.667.667.667h1.667v6c0 .368.298.667.667.667s.667-.298.667-.667v-6h1.667c.368 0 .667-.298.667-.667S10.535 6 10.166 6H8.5V4.667z',
  'facebook-fill':
    'M6.023 16L6 9H3V6h3V4c0-2.699 1.672-4 4.079-4 1.153 0 2.145.086 2.434.124v2.821l-1.67.001c-1.31 0-1.563.622-1.563 1.535V6H13l-1 3H9.28v7H6.023z',
  twitter:
    'M10.266 2c1.133 0 1.867.733 2.267 1.2l1.533-.533c.267-.067.6 0 .733.2.2.2.2.533 0 .8l-1.2 1.8c.133.8.267 3.2-1.533 5.6-1.467 2.133-3.8 3-6.067 3-1.6 0-3.267-.4-4.6-1.133-.267-.133-.4-.4-.333-.733.067-.267.267-.533.6-.533 1.133-.133 2-.333 2.8-.733-1.933-1.467-2.933-4.133-2.8-7.6 0-.267.2-.533.4-.6.267-.133.533-.067.733.133C4.133 4.2 5.733 5.6 7.266 5.467c0-.667.067-1.733.667-2.467.533-.667 1.333-1 2.333-1zm.067 1.333c-.6 0-1.067.133-1.333.533-.467.6-.4 1.667-.333 2.067.067.333-.133.667-.467.733-1.933.667-3.867-.6-5.2-1.733.2 2.133.867 4.4 3 5.467.2.133.333.333.333.533a.7.7 0 0 1-.333.6c-.6.4-1.267.733-1.933.933 2.533.6 5.333.133 7.067-2.2 1.8-2.333 1.267-4.667 1.2-4.8-.067-.2 0-.4.067-.533l.267-.4-.133.067c-.333.133-.667-.067-.8-.333l-.025-.04c-.114-.179-.617-.893-1.375-.893z',
  'twitter-fill':
    'M13.404 5.756l-.05-.353 1.256-1.884c.079-.118.074-.274-.012-.387s-.235-.159-.37-.114l-1.747.582a3.37 3.37 0 0 0-.412-.502c-.37-.37-.949-.764-1.736-.764-.815 0-1.418.228-1.849.603s-.651.856-.766 1.317-.125.912-.108 1.243l.02.26c-.837.178-1.712-.095-2.57-.604-.946-.561-1.813-1.377-2.491-2.055-.094-.094-.235-.123-.358-.074s-.206.167-.21.3c-.085 2.718.475 6.008 3.007 7.676-1.065.576-2.109.845-3.382 1.004-.146.018-.262.13-.287.274s.048.289.179.354c3.439 1.72 7.896 1.595 10.416-1.765 1.045-1.393 1.395-2.789 1.482-3.839.044-.524.022-.962-.011-1.272z',
  instagram:
    'M10.663 1.333a4 4 0 0 1 3.995 3.8l.005.2v5.333a4 4 0 0 1-3.8 3.995l-.2.005H5.33a4 4 0 0 1-3.995-3.8l-.005-.2V5.333a4 4 0 0 1 3.8-3.995l.2-.005h5.333zm0 1.333H5.33c-1.473 0-2.667 1.194-2.667 2.667v5.333c0 1.473 1.194 2.667 2.667 2.667h5.333c1.473 0 2.667-1.194 2.667-2.667V5.333c0-1.473-1.194-2.667-2.667-2.667zm-2.667 2c1.841 0 3.333 1.492 3.333 3.333s-1.492 3.333-3.333 3.333S4.663 9.841 4.663 8s1.492-3.333 3.333-3.333zm0 1.333a2 2 0 1 0 0 4 2 2 0 1 0 0-4zm3.333-2c.368 0 .667.298.667.667s-.298.667-.667.667-.667-.298-.667-.667S10.962 4 11.33 4z',
  pinterest:
    'M8.213 1.36c1.774-.082 3.6.551 4.647 1.897 2.665 3.427.088 8.076-3.526 8.076-.858 0-1.532-.188-2.045-.527a2.5 2.5 0 0 1-.5-.434l-1.177 3.824c-.108.352-.481.549-.833.441s-.549-.481-.441-.833l2.667-8.667c.108-.352.481-.549.833-.441s.549.481.441.833l-.807 2.624-.005.039c-.006.053-.013.133-.014.23-.002.199.022.445.108.678.084.225.223.434.464.593s.645.306 1.31.306c2.477 0 4.475-3.351 2.474-5.924-.713-.917-2.067-1.451-3.533-1.384-1.449.067-2.884.719-3.745 2.011-.56.84-.611 1.642-.447 2.248.175.647.565 1.003.807 1.08.351.112.544.487.433.838s-.487.544-.838.433c-.803-.256-1.436-1.067-1.689-2.003-.264-.977-.149-2.175.625-3.335C4.559 2.255 6.423 1.442 8.213 1.36z',
  mail: 'M12.666 2.667a2 2 0 0 1 2 2v6.667a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V4.667a2 2 0 0 1 2-2h9.333zm.667 3.246L9.109 8.729a2 2 0 0 1-2.219 0L2.666 5.913v5.421c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V5.913zM12.666 4H3.333c-.257 0-.481.146-.592.36h0L7.63 7.619c.224.149.516.149.74 0h0l4.889-3.259c-.111-.214-.334-.36-.592-.36h0z',
  payment:
    'M12.666 2.667a2 2 0 0 1 2 2v6.667a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V4.667a2 2 0 0 1 2-2h9.333zm.667 4H2.666v4.667c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V6.667zM12.666 4H3.333c-.368 0-.667.298-.667.667h0v.667h10.667v-.667c0-.368-.298-.667-.667-.667h0zM4 10c0-.368.298-.667.667-.667h4c.368 0 .667.298.667.667s-.298.667-.667.667h-4C4.298 10.667 4 10.369 4 10z',
  edit: 'M12.518 2.545l.943.943a2 2 0 0 1 0 2.828l-6.928 6.928c-.093.093-.212.157-.341.182l-3.536.707c-.466.093-.878-.318-.784-.784l.707-3.536c.026-.129.089-.248.182-.341L9.69 2.545a2 2 0 0 1 2.828 0zM8.747 5.373l-4.9 4.9-.471 2.357 2.357-.471 4.9-4.9-1.886-1.886zm2.828-1.886c-.26-.26-.682-.26-.943 0h0l-.943.943 1.886 1.886.943-.943c.26-.26.26-.682 0-.943h0z',
  upload:
    'M13.333 9.167c.368 0 .667.298.667.667v1.333a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9.833c0-.368.298-.667.667-.667s.667.298.667.667v1.333c0 .368.298.667.667.667h8c.368 0 .667-.298.667-.667V9.833c0-.368.298-.667.667-.667zM8.001 2.833c.177 0 .346.07.471.195l2.999 2.999c.26.26.26.682 0 .943s-.682.26-.943 0L8.668 5.109v4.724c0 .368-.298.667-.667.667s-.667-.298-.667-.667V5.109L5.473 6.971c-.26.26-.682.26-.943 0s-.26-.682 0-.943l3-3c.125-.125.295-.195.471-.195z',
  expand:
    'M1.333 3.333a2 2 0 0 1 2-2h1.333c.368 0 .667.298.667.667s-.298.667-.667.667H3.333c-.368 0-.667.298-.667.667v1.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667V3.333zm0 9.333a2 2 0 0 0 2 2h1.333c.368 0 .667-.298.667-.667s-.298-.667-.667-.667H3.333c-.368 0-.667-.298-.667-.667v-1.333c0-.368-.298-.667-.667-.667s-.667.298-.667.667v1.333zm13.333 0a2 2 0 0 1-2 2h-1.333c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h1.333c.368 0 .667-.298.667-.667v-1.333c0-.368.298-.667.667-.667s.667.298.667.667v1.333zm0-9.333a2 2 0 0 0-2-2h-1.333c-.368 0-.667.298-.667.667s.298.667.667.667h1.333c.368 0 .667.298.667.667v1.333c0 .368.298.667.667.667s.667-.298.667-.667V3.333z',
  calendar:
    'M11.333 1.333c.368 0 .667.298.667.667v.667h.667a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2H4V2c0-.368.298-.667.667-.667s.667.298.667.667v.667h5.333V2c0-.368.298-.667.667-.667zm2 6.667H2.666v4.667c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V8zM4 4h-.667c-.368 0-.667.298-.667.667h0v2h10.667v-2c0-.368-.298-.667-.667-.667h0H12v.667c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0V4H5.333v.667c0 .368-.298.667-.667.667S4 5.035 4 4.666h0V4z',
  list: 'M13.333 10.667c.368 0 .667.298.667.667s-.298.667-.667.667h-8c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h8zm-10.667 0c.368 0 .667.298.667.667S3.035 12 2.667 12 2 11.702 2 11.333s.298-.667.667-.667zm10.667-3.333c.368 0 .667.298.667.667s-.298.667-.667.667h-8c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h8zm-10.667 0c.368 0 .667.298.667.667s-.298.667-.667.667S2 8.368 2 8s.298-.667.667-.667zM13.333 4c.368 0 .667.298.667.667s-.298.667-.667.667h-8c-.368 0-.667-.298-.667-.667S4.965 4 5.333 4h8zM2.667 4c.368 0 .667.298.667.667s-.298.667-.667.667S2 5.035 2 4.667 2.298 4 2.667 4z',
  clock:
    'M8 1.333A6.67 6.67 0 0 1 14.666 8 6.67 6.67 0 0 1 8 14.666 6.67 6.67 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 1.333C5.054 2.666 2.666 5.054 2.666 8S5.054 13.333 8 13.333 13.333 10.945 13.333 8 10.945 2.666 8 2.666zM8 4c.368 0 .667.298.667.667v3.057l1.471 1.471c.26.26.26.682 0 .943s-.682.26-.943 0L7.724 8.666c-.25-.25-.391-.589-.391-.943V4.666C7.333 4.298 7.631 4 8 4z',
  'star-stroke':
    'M6.741 2.563c.415-1.194 2.104-1.194 2.519 0h0l.63 1.813c.183.527.675.884 1.232.895h0l1.919.039c1.264.026 1.786 1.632.778 2.396h0l-1.53 1.16c-.444.337-.632.915-.471 1.449h0l.556 1.838c.366 1.21-1 2.203-2.038 1.481h0l-1.576-1.097c-.458-.319-1.065-.319-1.523 0h0l-1.576 1.097c-1.038.722-2.404-.271-2.038-1.481h0l.556-1.838c.161-.534-.026-1.112-.471-1.449h0l-1.53-1.16c-1.007-.764-.486-2.37.778-2.396h0l1.919-.039c.558-.011 1.049-.369 1.232-.895h0zM8 3l-.63 1.813C7.003 5.867 6.02 6.582 4.905 6.604l-1.919.039 1.53 1.16c.889.674 1.264 1.83.941 2.897l-.556 1.838 1.576-1.097c.916-.637 2.131-.637 3.046 0l1.576 1.097-.556-1.838c-.323-1.068.053-2.223.941-2.897l1.53-1.16-1.919-.039C9.98 6.582 8.997 5.867 8.63 4.814L8 3z',
  'star-fill':
    'M6.758 2.563c.415-1.194 2.104-1.194 2.519 0l.63 1.813c.183.527.675.884 1.232.895l1.919.039c1.264.026 1.786 1.632.778 2.396l-1.53 1.16c-.444.337-.632.915-.471 1.449l.556 1.838c.366 1.21-1 2.203-2.038 1.481l-1.576-1.097c-.458-.319-1.065-.319-1.523 0L5.68 13.633c-1.038.722-2.404-.271-2.038-1.481l.556-1.838c.161-.534-.026-1.112-.471-1.449l-1.53-1.16c-1.007-.764-.486-2.37.778-2.396l1.919-.039c.558-.011 1.049-.369 1.232-.895l.63-1.813z',
  smile:
    'M8 1.333A6.67 6.67 0 0 1 14.666 8 6.67 6.67 0 0 1 8 14.666 6.67 6.67 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 1.333C5.054 2.666 2.666 5.054 2.666 8S5.054 13.333 8 13.333 13.333 10.945 13.333 8 10.945 2.666 8 2.666zm3.323 6.056c.337.147.491.54.344.878a4 4 0 0 1-7.334 0c-.147-.337.007-.73.344-.878s.73.007.878.344c.412.943 1.353 1.6 2.445 1.6s2.033-.657 2.445-1.6c.147-.337.54-.491.878-.344zm-5.99-3.389c.368 0 .667.298.667.667s-.298.667-.667.667-.667-.298-.667-.667.298-.667.667-.667zm5.333 0c.368 0 .667.298.667.667s-.298.667-.667.667S10 6.368 10 6s.298-.667.667-.667z',
  repeat:
    'M10.805 1.862a.67.67 0 0 0-.943 0c-.261.26-.26.682 0 .943l.526.526H6.667A4.67 4.67 0 0 0 2 7.998v.667c0 .368.298.667.667.667s.667-.299.667-.667v-.667c0-1.841 1.492-3.333 3.333-3.333h3.724l-.529.529a.67.67 0 0 0 0 .943c.26.261.682.26.943 0l1.43-1.43a1 1 0 0 0 0-1.414l-1.43-1.43zM9.332 12.667A4.67 4.67 0 0 0 13.999 8v-.667c0-.368-.298-.667-.667-.667s-.667.298-.667.667V8c0 1.841-1.492 3.333-3.333 3.333H5.608l.529-.529a.67.67 0 0 0 0-.943c-.26-.261-.682-.26-.943 0l-1.43 1.43a1 1 0 0 0 0 1.414l1.43 1.43a.67.67 0 0 0 .943 0c.261-.26.26-.682 0-.943l-.526-.526h3.721z',
  mobile:
    'M10.666 1.333a2 2 0 0 1 2 2v9.333a2 2 0 0 1-2 2H5.333a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h5.333zM6 2.666h-.667c-.368 0-.667.298-.667.667v9.333c0 .368.298.667.667.667h5.333c.368 0 .667-.298.667-.667V3.333c0-.368-.298-.667-.667-.667H10a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1z',
  tablet:
    'M12 1.333a2 2 0 0 1 1.995 1.851l.005.149v9.333a2 2 0 0 1-1.851 1.995l-.149.005H4a2 2 0 0 1-1.995-1.851L2 12.666V3.333a2 2 0 0 1 1.851-1.995L4 1.333h8zm0 1.333H4c-.368 0-.667.298-.667.667v9.333c0 .368.298.667.667.667h8c.368 0 .667-.298.667-.667V3.333c0-.368-.298-.667-.667-.667zm-4 8.667c.368 0 .667.298.667.667s-.298.667-.667.667-.667-.298-.667-.667.298-.667.667-.667z',
  desktop:
    'M14.667 12.667c.368 0 .667.298.667.667s-.298.667-.667.667H1.334c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h13.333zM12.667 2a2 2 0 0 1 1.995 1.851l.005.149v6a2 2 0 0 1-1.851 1.995l-.149.005H3.334a2 2 0 0 1-1.995-1.851L1.334 10V4a2 2 0 0 1 1.851-1.995L3.334 2h9.333zm0 1.333H3.334c-.368 0-.667.298-.667.667v6c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V4c0-.368-.298-.667-.667-.667z',
  filter:
    'M13.333 1.467c.736 0 1.333.597 1.333 1.333v1.028a2 2 0 0 1-.764 1.573L10 8.467v4.454c0 .283-.179.535-.445.629l-2.667.938c-.434.153-.888-.169-.888-.629V8.467L2.097 5.401a2 2 0 0 1-.764-1.573V2.8c0-.736.597-1.333 1.333-1.333h10.667zm0 1.2H2.666v1.028c0 .205.094.398.255.524l3.902 3.066c.322.253.51.639.51 1.048v4.451l1.333-.469V8.334c0-.409.188-.796.51-1.048l3.902-3.066c.161-.126.255-.32.255-.524V2.667z',
  mouse:
    'M7.834 1.333a4.67 4.67 0 0 1 4.662 4.453L12.5 6v4a4.67 4.67 0 0 1-4.667 4.667 4.67 4.67 0 0 1-4.662-4.453L3.167 10V6a4.67 4.67 0 0 1 4.667-4.667zm0 1.333C5.993 2.666 4.5 4.159 4.5 6v4c0 1.841 1.492 3.333 3.333 3.333s3.333-1.492 3.333-3.333V6c0-1.841-1.492-3.333-3.333-3.333zm0 1.333c.368 0 .667.298.667.667v2c0 .368-.298.667-.667.667s-.667-.298-.667-.667v-2c0-.368.298-.667.667-.667z',
  image:
    'M12.667 1.333a2 2 0 0 1 2 2v9.333a2 2 0 0 1-2 2H3.334a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h9.333zM4.036 6.424L2.822 7.881c-.1.12-.155.271-.155.427v4.359c0 .368.298.667.667.667h9.333l.055-.002c.322-.027.316-.434.098-.672L7.047 6.353a2 2 0 0 0-3.011.071zm7.298-3.091c-.736 0-1.333.597-1.333 1.333S10.598 6 11.334 6s1.333-.597 1.333-1.333-.597-1.333-1.333-1.333z',
  'image-stroke':
    'M12.667 1.333a2 2 0 0 1 2 2v9.333a2 2 0 0 1-2 2H3.334a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h9.333zM5.06 7.278l-2.393 2.871v2.517c0 .368.298.667.667.667h8.302L6.064 7.254c-.273-.298-.745-.286-1.004.024zm7.607-4.611H3.334c-.335 0-.612.247-.659.568l-.007.099v4.733l1.369-1.642a2 2 0 0 1 2.896-.187l.115.116 6.085 6.638.066.08c.064-.084.109-.184.127-.293l.009-.111V3.333c0-.368-.298-.667-.667-.667zm-2 1.333c.736 0 1.333.597 1.333 1.333s-.597 1.333-1.333 1.333-1.333-.597-1.333-1.333S9.931 4 10.667 4z',
  video:
    'M3.334 3.333a2 2 0 0 0-2 2v5.333a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-.381l1.225.875c.882.63 2.108 0 2.108-1.085V5.924c0-1.085-1.226-1.715-2.108-1.085l-1.225.875v-.381a2 2 0 0 0-2-2h-6z',
  'video-stroke':
    'M9.334 3.333a2 2 0 0 1 2 2v.381l1.225-.875c.882-.63 2.108 0 2.108 1.085v4.152c0 1.084-1.226 1.715-2.108 1.085l-1.225-.875v.381a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2V5.333a2 2 0 0 1 2-2h6zm0 1.333h-6c-.368 0-.667.298-.667.667h0v5.333c0 .368.298.667.667.667h0 6c.368 0 .667-.298.667-.667h0V5.333c0-.368-.298-.667-.667-.667h0zm4 1.258l-2 1.429v1.294l2 1.429V5.924z',
  setting:
    'M8.414.673c.574 0 1.083.367 1.265.912l.258.775c.122.364.399.651.735.838l.155.09c.33.198.717.295 1.094.218l.801-.164c.562-.115 1.135.143 1.422.64l.414.717c.287.497.224 1.122-.157 1.551l-.543.613c-.255.287-.365.67-.359 1.054v.181c-.006.384.104.767.359 1.054l.543.613c.381.429.444 1.054.157 1.551l-.414.717c-.287.497-.86.755-1.422.64l-.801-.164c-.377-.077-.764.02-1.094.218l-.155.09c-.336.187-.613.473-.735.838l-.258.775c-.181.544-.691.912-1.265.912h-.828c-.574 0-1.083-.367-1.265-.912l-.258-.775c-.121-.364-.399-.651-.734-.838l-.155-.09c-.33-.198-.717-.295-1.094-.218l-.801.164c-.562.115-1.135-.143-1.422-.64l-.414-.717c-.287-.497-.224-1.122.157-1.551l.543-.613c.255-.287.365-.67.359-1.054v-.181a1.59 1.59 0 0 0-.258-.925l-.1-.129-.543-.613c-.349-.394-.431-.951-.222-1.425l.064-.127.414-.717c.265-.459.773-.714 1.292-.66l.13.02.801.164c.377.077.764-.02 1.094-.218l.155-.09c.336-.187.613-.473.734-.838l.258-.775c.181-.544.691-.912 1.265-.912h.828zm0 1.333h-.828l-.258.775c-.25.75-.795 1.272-1.351 1.581l-.117.068c-.547.328-1.272.54-2.047.381l-.801-.164-.414.717.543.613c.476.536.668 1.19.692 1.783l.002.176v.139c.009.58-.14 1.237-.56 1.795l-.134.164-.543.613.414.717.801-.164c.775-.159 1.501.053 2.047.381l.117.068c.556.31 1.101.831 1.351 1.581l.258.775h.828l.258-.775c.25-.75.795-1.272 1.351-1.581l.117-.068c.547-.328 1.272-.54 2.047-.381l.801.164.414-.717-.543-.613c-.523-.59-.704-1.322-.694-1.959v-.139c-.01-.638.171-1.37.694-1.96l.543-.613-.414-.717-.801.164c-.775.159-1.501-.053-2.047-.381l-.117-.068c-.556-.309-1.101-.831-1.351-1.581l-.258-.775zM8 5.34c1.473 0 2.667 1.194 2.667 2.667S9.473 10.673 8 10.673 5.334 9.479 5.334 8.006 6.528 5.34 8 5.34zm0 1.333c-.736 0-1.333.597-1.333 1.333S7.264 9.34 8 9.34s1.333-.597 1.333-1.333S8.737 6.673 8 6.673z',
  'file-add':
    'M10.667 1.333l.007.007a2 2 0 0 1 1.245.579l1.495 1.495a2 2 0 0 1 .579 1.245l.007.007v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h6.667zM10 2.666H4c-.335 0-.612.247-.659.568l-.007.099v9.333c0 .335.247.612.568.659l.099.007h8c.335 0 .612-.247.659-.568l.007-.099V5.333h-1.333c-.693 0-1.263-.529-1.327-1.205L10 4V2.666zM8 6c.368 0 .667.298.667.667h0V8H10c.368 0 .667.298.667.667s-.298.667-.667.667h0-1.333v1.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0V9.333H6c-.368 0-.667-.298-.667-.667S5.632 8 6 8h0 1.333V6.666C7.333 6.298 7.632 6 8 6z',
  copy: 'M12 4.667a2 2 0 0 1 1.995 1.851l.005.149V12a2 2 0 0 1-1.851 1.995L12 14H6.667a2 2 0 0 1-1.995-1.851L4.667 12v-.667H4a2 2 0 0 1-2-2h0V4a2 2 0 0 1 2-2h0 5.333a2 2 0 0 1 2 2h0v.667H12zM12 6H6.667C6.298 6 6 6.298 6 6.667V12c0 .368.298.667.667.667H12c.368 0 .667-.298.667-.667V6.667c0-.368-.298-.667-.667-.667zm-2-2c0-.368-.298-.667-.667-.667h0H4c-.368 0-.667.298-.667.667h0v5.333c0 .368.298.667.667.667h0 .667V6.667a2 2 0 0 1 1.851-1.995l.149-.005H10z',
  download:
    'M13.333 9.333c.368 0 .667.298.667.667v1.333a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10c0-.368.298-.667.667-.667s.667.298.667.667v1.333c0 .368.298.667.667.667h8c.368 0 .667-.298.667-.667V10c0-.368.298-.667.667-.667zM8.001 2.666c.368 0 .667.298.667.667h0v5.391l1.861-1.861c.26-.26.682-.26.943 0s.26.682 0 .943h0l-2.527 2.527c-.521.521-1.365.521-1.886 0h0L4.53 7.804c-.26-.26-.26-.682 0-.943s.682-.26.943 0h0l1.862 1.862V3.333c0-.368.299-.667.667-.667z',
  sort: 'M4 6C4 5.44772 4.44772 5 5 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H5C4.44772 7 4 6.55228 4 6Z M4 12C4 11.4477 4.44772 11 5 11H15C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13H5C4.44772 13 4 12.5523 4 12Z M4 18C4 17.4477 4.44772 17 5 17H11C11.5523 17 12 17.4477 12 18C12 18.5523 11.5523 19 11 19H5C4.44772 19 4 18.5523 4 18Z',
  share:
    'M4.667 10.666C3.194 10.666 2 9.472 2 8s1.194-2.667 2.667-2.667a2.66 2.66 0 0 1 1.886.781l-.015-.015h0l2.238-1.343.008.028c-.076-.248-.117-.511-.117-.784 0-1.473 1.194-2.667 2.667-2.667S14 2.527 14 4s-1.194 2.667-2.667 2.667c-.68 0-1.3-.254-1.771-.673l-.1-.094h0L7.224 7.242l-.015-.05c.081.255.124.526.124.807 0 .273-.041.536-.117.784l.008-.028 2.238 1.343h0l-.015.015a2.66 2.66 0 0 1 1.886-.781C12.806 9.333 14 10.527 14 12s-1.194 2.667-2.667 2.667S8.667 13.472 8.667 12c0-.281.044-.553.124-.807l-.015.05-2.238-1.343h0l-.1.094c-.471.419-1.091.673-1.771.673zm6.667 0c-.736 0-1.333.597-1.333 1.333s.597 1.333 1.333 1.333 1.333-.597 1.333-1.333-.597-1.333-1.333-1.333zm-6.667-4c-.736 0-1.333.597-1.333 1.333s.597 1.333 1.333 1.333S6 8.736 6 8s-.597-1.333-1.333-1.333zm6.667-4C10.597 2.666 10 3.263 10 4s.597 1.333 1.333 1.333S12.667 4.736 12.667 4s-.597-1.333-1.333-1.333z',
  user: 'M3 18V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V18C21 15.2386 18.7614 13 16 13H8C5.23858 13 3 15.2386 3 18Z M12 1.5C9.51472 1.5 7.5 3.51472 7.5 6C7.5 8.48528 9.51472 10.5 12 10.5C14.4853 10.5 16.5 8.48528 16.5 6C16.5 3.51472 14.4853 1.5 12 1.5Z',
  lock: 'M8 1.333c1.841 0 3.333 1.492 3.333 3.333v.667a2 2 0 0 1 2 2v5.333a2 2 0 0 1-2 2H4.667a2 2 0 0 1-2-2V7.333a2 2 0 0 1 2-2v-.667c0-1.841 1.492-3.333 3.333-3.333zm3.333 5.333H4.667c-.335 0-.612.247-.659.568L4 7.333v5.333c0 .335.247.612.568.659l.099.007h6.667c.335 0 .612-.247.659-.568l.007-.099V7.333c0-.368-.298-.667-.667-.667zM8 8c.736 0 1.333.597 1.333 1.333 0 .494-.268.924-.667 1.155h0v.845c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0v-.845c-.399-.231-.667-.661-.667-1.155C6.667 8.597 7.264 8 8 8zm0-5.333a2 2 0 0 0-1.995 1.851L6 4.666v.667h4v-.667a2 2 0 0 0-2-2z',
  chevron:
    'M0.195262 5.30474C0.455612 5.56509 0.877722 5.56509 1.13807 5.30474L5 1.44281L8.86193 5.30474C9.12228 5.56509 9.54439 5.56509 9.80474 5.30474C10.0651 5.04439 10.0651 4.62228 9.80474 4.36193L5.94281 0.500001C5.42211 -0.0206986 4.57789 -0.0206977 4.05719 0.500001L0.195262 4.36193C-0.0650872 4.62228 -0.0650873 5.04439 0.195262 5.30474Z',
  show: 'M13.2655 5.75558C12.4733 7.07654 10.4767 9.66668 7 9.66668C3.52332 9.66668 1.52672 7.07654 0.734527 5.75558C0.453016 5.28617 0.453016 4.71385 0.734527 4.24444C1.52672 2.92348 3.52332 0.333344 7 0.333344C10.4767 0.333344 12.4733 2.92348 13.2655 4.24444C13.547 4.71385 13.547 5.28617 13.2655 5.75558ZM7 7.00001C8.10457 7.00001 9 6.10458 9 5.00001C9 3.89544 8.10457 3.00001 7 3.00001C5.89543 3.00001 5 3.89544 5 5.00001C5 6.10458 5.89543 7.00001 7 7.00001Z',
  attach:
    'M18.6997 7.61397C17.5281 6.44239 15.6286 6.44239 14.4571 7.61397L8.80022 13.2708C8.40969 13.6613 8.40969 14.2945 8.80022 14.685C9.19074 15.0756 9.82391 15.0756 10.2144 14.685L15.8713 9.02818C16.2618 8.63765 16.895 8.63765 17.2855 9.02818C17.676 9.4187 17.676 10.0519 17.2855 10.4424L11.6286 16.0992C10.4571 17.2708 8.55758 17.2708 7.386 16.0992C6.21443 14.9277 6.21443 13.0282 7.386 11.8566L13.0429 6.19975C14.9955 4.24713 18.1613 4.24713 20.1139 6.19975C22.0665 8.15237 22.0665 11.3182 20.1139 13.2708L14.4571 18.9277C11.7234 21.6613 7.29125 21.6613 4.55758 18.9277C1.82391 16.194 1.82391 11.7618 4.55758 9.02818L10.2144 3.37132C10.605 2.9808 11.2381 2.9808 11.6286 3.37132C12.0192 3.76185 12.0192 4.39501 11.6286 4.78554L5.97179 10.4424C4.01917 12.395 4.01917 15.5608 5.97179 17.5135C7.92441 19.4661 11.0902 19.4661 13.0429 17.5135L18.6997 11.8566C19.8713 10.685 19.8713 8.78554 18.6997 7.61397Z',
  send: 'M0.192795 4.57844C-0.638174 2.08552 1.91012 -0.195313 4.29602 0.905875L18.0983 7.27616C20.422 8.34867 20.422 11.6514 18.0983 12.7239L4.29603 19.0942C1.91012 20.1953 -0.638168 17.9145 0.192805 15.4216L1.68376 10.9487C1.88903 10.3329 1.88903 9.66716 1.68377 9.05136L0.192795 4.57844ZM3.4579 2.72179L17.2601 9.09208C18.0347 9.44958 18.0347 10.5505 17.2601 10.908L3.45791 17.2782C2.66261 17.6453 1.81318 16.885 2.09017 16.054L3.58113 11.5812C3.64486 11.39 3.69672 11.1959 3.73671 11H12C12.5523 11 13 10.5523 13 10C13 9.44772 12.5523 9.00001 12 9.00001H3.73669C3.69671 8.80414 3.64486 8.61008 3.58113 8.41891L2.09016 3.94598C1.81317 3.11501 2.66261 2.35473 3.4579 2.72179Z',
  'sort-arrow':
    'M5 1C5 0.447715 4.55228 0 4 0C3.44772 0 3 0.447715 3 1V13.0858L1.70711 11.7929C1.31658 11.4024 0.683418 11.4024 0.292893 11.7929C-0.0976311 12.1834 -0.0976311 12.8166 0.292893 13.2071L2.58579 15.5C3.36683 16.281 4.63316 16.281 5.41421 15.5L7.70711 13.2071C8.09763 12.8166 8.09763 12.1834 7.70711 11.7929C7.31658 11.4024 6.68342 11.4024 6.29289 11.7929L5 13.0858V1Z',
  file: 'M3 0C1.34315 0 0 1.34315 0 3V17C0 18.6569 1.34315 20 3 20H15C16.6569 20 18 18.6569 18 17V5.74264C18 4.94699 17.6839 4.18393 17.1213 3.62132L14.3787 0.87868C13.8161 0.316071 13.053 0 12.2574 0H3ZM12 2.2071V4C12 5.10457 12.8954 6 14 6H15.7929C16.2383 6 16.4614 5.46143 16.1464 5.14644L12.8536 1.85355C12.5386 1.53857 12 1.76165 12 2.2071Z',
  'file-outline':
    'M21 19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V5C3 3.34315 4.34315 2 6 2H16L16.0107 2.01071C16.7136 2.07025 17.3761 2.3761 17.8787 2.87868L20.1213 5.12132C20.6239 5.6239 20.9297 6.28645 20.9893 6.98929L21 7V19ZM18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44771 4 6 4H15V6C15 7.10457 15.8954 8 17 8H19V19C19 19.5523 18.5523 20 18 20Z',
  'donut-chart':
    'M7.333 3.334v2.661c0 .368-.306.656-.654.776-.161.056-.316.132-.46.228-.33.22-.587.534-.739.9s-.191.77-.114 1.159.268.746.549 1.027.638.471 1.027.549.792.038 1.159-.114.68-.409.9-.739c.096-.144.172-.298.228-.46.12-.348.408-.654.776-.654h2.661c.368 0 .67.299.63.665a6 6 0 0 1-3.667 4.878A6 6 0 0 1 3.09 12.91 6 6 0 0 1 1.79 6.371a6 6 0 0 1 4.878-3.667c.366-.041.665.261.665.63zM6 4.195c-.445.133-.869.331-1.259.592-.767.513-1.366 1.242-1.719 2.094s-.446 1.791-.266 2.696.625 1.737 1.277 2.389 1.484 1.097 2.389 1.277 1.844.088 2.696-.266 1.582-.951 2.094-1.719c.261-.39.459-.815.592-1.259h-1.411a3.34 3.34 0 0 1-1.783 1.751 3.34 3.34 0 0 1-3.638-.724 3.34 3.34 0 0 1-.724-3.638A3.34 3.34 0 0 1 6 5.606V4.195zm3.332-2.824a6 6 0 0 1 3.577 1.72 6 6 0 0 1 1.72 3.577c.041.366-.261.665-.63.665h-2.661c-.368 0-.656-.306-.776-.654l-.043-.113c-.101-.243-.248-.464-.435-.651s-.407-.334-.651-.435l-.113-.043c-.348-.12-.654-.408-.654-.776V2c0-.368.299-.67.665-.63zM10 2.862v1.411A3.34 3.34 0 0 1 11.727 6h1.411c-.046-.153-.099-.304-.161-.453-.235-.566-.578-1.081-1.012-1.514s-.948-.777-1.514-1.012A4.67 4.67 0 0 0 10 2.862z',
  dollar:
    'M8.667 15.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667V14.61c-1.095-.106-2.115-.562-2.78-1.227-.26-.26-.26-.682 0-.943s.682-.26.943 0c.571.571 1.541.894 2.504.894.963 0 1.667-.444 1.667-1.167 0-.611-.501-.972-1.843-1.285-1.827-.427-3.157-1.107-3.157-2.715 0-1.379 1.117-2.363 2.667-2.557V4.667c0-.368.298-.667.667-.667s.667.298.667.667v.943c.937.106 1.799.457 2.447.972.283.225.33.637.105.92s-.637.33-.92.105c-.529-.42-1.223-.664-1.965-.664-.963 0-1.667.444-1.667 1.167 0 .611.501.972 1.843 1.285 1.827.427 3.157 1.107 3.157 2.715 0 1.379-1.117 2.363-2.667 2.557v.723z',

  ruble:
    'M6 2.667c0-.368.298-.667.667-.667h2.667A3.33 3.33 0 0 1 12.667 5.333 3.33 3.33 0 0 1 9.334 8.666H7.333v1.333h2c.368 0 .667.298.667.667s-.298.667-.667.667h-2v2c0 .368-.298.667-.667.667s-.667-.298-.667-.667v-2H5.333c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h.667v-1.333H5.333c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h.667V2.667zm1.333.666v4h2A2 2 0 0 0 11.334 5.333 2 2 0 0 0 9.334 3.333h-2z',
  power:
    'M15.6324 4.87106C15.8829 4.37946 16.4883 4.17952 16.9491 4.48291C18.3473 5.40342 19.467 6.69989 20.1733 8.23206C21.0196 10.0679 21.2233 12.1353 20.7513 14.101C20.2794 16.0667 19.1594 17.8163 17.5719 19.0678C15.9843 20.3194 14.0216 21 12 21C9.97845 21 8.01575 20.3194 6.42817 19.0679C4.8406 17.8163 3.72061 16.0667 3.24868 14.101C2.77675 12.1353 2.98036 10.068 3.82671 8.23208C4.53305 6.6999 5.65273 5.40343 7.05089 4.48292C7.51172 4.17953 8.11713 4.37947 8.36761 4.87107C8.61809 5.36266 8.41731 5.95874 7.96632 6.27658C6.96389 6.98306 6.1595 7.94424 5.64118 9.06856C4.98273 10.4969 4.82431 12.1053 5.19148 13.6346C5.55864 15.1639 6.42999 16.5251 7.66512 17.4988C8.90025 18.4725 10.4272 19.002 12 19.002C13.5728 19.002 15.0998 18.4725 16.3349 17.4988C17.57 16.5251 18.4414 15.1639 18.8085 13.6346C19.1757 12.1053 19.0173 10.4969 18.3588 9.06854C17.8405 7.94423 17.0361 6.98305 16.0337 6.27657C15.5827 5.95873 15.3819 5.36265 15.6324 4.87106Z',
  brain:
    'M5 6.5H10M5 10H13M7.68375 16H14.2C15.8802 16 16.7202 16 17.362 15.673C17.9265 15.3854 18.3854 14.9265 18.673 14.362C19 13.7202 19 12.8802 19 11.2V5.8C19 4.11984 19 3.27976 18.673 2.63803C18.3854 2.07354 17.9265 1.6146 17.362 1.32698C16.7202 1 15.8802 1 14.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V18.3355C1 18.8684 1 19.1348 1.10923 19.2716C1.20422 19.3906 1.34827 19.4599 1.50054 19.4597C1.67563 19.4595 1.88367 19.2931 2.29976 18.9602L4.68521 17.0518C5.17252 16.662 5.41617 16.4671 5.68749 16.3285C5.9282 16.2055 6.18443 16.1156 6.44921 16.0613C6.74767 16 7.0597 16 7.68375 16Z',
  letter:
    'M1 4L9.16492 9.71544C9.82609 10.1783 10.1567 10.4097 10.5163 10.4993C10.8339 10.5785 11.1661 10.5785 11.4837 10.4993C11.8433 10.4097 12.1739 10.1783 12.8351 9.71544L21 4M5.8 17H16.2C17.8802 17 18.7202 17 19.362 16.673C19.9265 16.3854 20.3854 15.9265 20.673 15.362C21 14.7202 21 13.8802 21 12.2V5.8C21 4.11984 21 3.27976 20.673 2.63803C20.3854 2.07354 19.9265 1.6146 19.362 1.32698C18.7202 1 17.8802 1 16.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V12.2C1 13.8802 1 14.7202 1.32698 15.362C1.6146 15.9265 2.07354 16.3854 2.63803 16.673C3.27976 17 4.11984 17 5.8 17Z',
  users:
    'M15 1.46776C16.4817 2.20411 17.5 3.73314 17.5 5.5C17.5 7.26686 16.4817 8.79589 15 9.53224M17 14.7664C18.5115 15.4503 19.8725 16.565 21 18M1 18C2.94649 15.5226 5.58918 14 8.5 14C11.4108 14 14.0535 15.5226 16 18M13 5.5C13 7.98528 10.9853 10 8.5 10C6.01472 10 4 7.98528 4 5.5C4 3.01472 6.01472 1 8.5 1C10.9853 1 13 3.01472 13 5.5Z',
  holiday:
    'M19 9H1M14 1V5M6 1V5M8.5 13L10 12V17M8.75 17H11.25M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z',
  call: 'M14.0497 5C15.0264 5.19057 15.924 5.66826 16.6277 6.37194C17.3314 7.07561 17.8091 7.97326 17.9997 8.95M14.0497 1C16.0789 1.22544 17.9713 2.13417 19.4159 3.57701C20.8606 5.01984 21.7717 6.91101 21.9997 8.94M10.2266 12.8631C9.02506 11.6615 8.07627 10.3028 7.38028 8.85323C7.32041 8.72854 7.29048 8.66619 7.26748 8.5873C7.18576 8.30695 7.24446 7.96269 7.41447 7.72526C7.46231 7.65845 7.51947 7.60129 7.63378 7.48698C7.98338 7.13737 8.15819 6.96257 8.27247 6.78679C8.70347 6.1239 8.70347 5.26932 8.27247 4.60643C8.15819 4.43065 7.98338 4.25585 7.63378 3.90624L7.43891 3.71137C6.90747 3.17993 6.64174 2.91421 6.35636 2.76987C5.7888 2.4828 5.11854 2.4828 4.55098 2.76987C4.2656 2.91421 3.99987 3.17993 3.46843 3.71137L3.3108 3.86901C2.78117 4.39863 2.51636 4.66344 2.31411 5.02348C2.08969 5.42298 1.92833 6.04347 1.9297 6.5017C1.93092 6.91464 2.01103 7.19687 2.17124 7.76131C3.03221 10.7947 4.65668 13.6571 7.04466 16.045C9.43264 18.433 12.295 20.0575 15.3284 20.9185C15.8928 21.0787 16.1751 21.1588 16.588 21.16C17.0462 21.1614 17.6667 21 18.0662 20.7756C18.4263 20.5733 18.6911 20.3085 19.2207 19.7789L19.3783 19.6213C19.9098 19.0898 20.1755 18.8241 20.3198 18.5387C20.6069 17.9712 20.6069 17.3009 20.3198 16.7333C20.1755 16.448 19.9098 16.1822 19.3783 15.6508L19.1835 15.4559C18.8339 15.1063 18.6591 14.9315 18.4833 14.8172C17.8204 14.3862 16.9658 14.3862 16.3029 14.8172C16.1271 14.9315 15.9523 15.1063 15.6027 15.4559C15.4884 15.5702 15.4313 15.6274 15.3644 15.6752C15.127 15.8453 14.7828 15.904 14.5024 15.8222C14.4235 15.7992 14.3612 15.7693 14.2365 15.7094C12.7869 15.0134 11.4282 14.0646 10.2266 12.8631Z',
  briefcase:
    'M15 5C15 4.07003 15 3.60504 14.8978 3.22354C14.6204 2.18827 13.8117 1.37962 12.7765 1.10222C12.395 1 11.93 1 11 1C10.07 1 9.60504 1 9.22354 1.10222C8.18827 1.37962 7.37962 2.18827 7.10222 3.22354C7 3.60504 7 4.07003 7 5M4.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H4.2C3.07989 5 2.51984 5 2.09202 5.21799C1.71569 5.40973 1.40973 5.71569 1.21799 6.09202C1 6.51984 1 7.07989 1 8.2V15.8C1 16.9201 1 17.4802 1.21799 17.908C1.40973 18.2843 1.71569 18.5903 2.09202 18.782C2.51984 19 3.0799 19 4.2 19Z',
  'phone-incoming': {
    paths: [
      {
        d: 'M7.38028 9.85323C8.07627 11.3029 9.02506 12.6615 10.2266 13.8631C11.4282 15.0646 12.7869 16.0134 14.2365 16.7094C14.3612 16.7693 14.4235 16.7992 14.5024 16.8222C14.7828 16.904 15.127 16.8453 15.3644 16.6752C15.4313 16.6274 15.4884 16.5702 15.6027 16.4559C15.9523 16.1063 16.1271 15.9315 16.3029 15.8172C16.9658 15.3862 17.8204 15.3862 18.4833 15.8172C18.6591 15.9315 18.8339 16.1063 19.1835 16.4559L19.3783 16.6508C19.9098 17.1822 20.1755 17.448 20.3198 17.7333C20.6069 18.3009 20.6069 18.9712 20.3198 19.5387C20.1755 19.8241 19.9098 20.0898 19.3783 20.6213L19.2207 20.7789C18.6911 21.3085 18.4263 21.5734 18.0662 21.7756C17.6667 22 17.0462 22.1614 16.588 22.16C16.1751 22.1588 15.8928 22.0787 15.3284 21.9185C12.295 21.0575 9.43264 19.433 7.04466 17.0451C4.65668 14.6571 3.03221 11.7947 2.17124 8.76131C2.01103 8.19687 1.93092 7.91464 1.9297 7.5017C1.92833 7.04348 2.08969 6.42298 2.31411 6.02348C2.51636 5.66345 2.78117 5.39863 3.3108 4.86901L3.46843 4.71138C3.99987 4.17993 4.2656 3.91421 4.55098 3.76987C5.11854 3.4828 5.7888 3.4828 6.35636 3.76987C6.64174 3.91421 6.90746 4.17993 7.43891 4.71138L7.63378 4.90625C7.98338 5.25585 8.15819 5.43066 8.27247 5.60643C8.70347 6.26932 8.70347 7.1239 8.27247 7.78679C8.15819 7.96257 7.98338 8.13738 7.63378 8.48698C7.51947 8.60129 7.46231 8.65845 7.41447 8.72526C7.24446 8.96269 7.18576 9.30695 7.26748 9.58731C7.29048 9.6662 7.32041 9.72854 7.38028 9.85323Z',
        fill: '#83BF6E',
      },
      {
        d: 'M14.9961 4V9M14.9961 9L19.9961 9M14.9961 9L20.9961 3',
        stroke: '#83BF6E',
        strokeWidth: '1.5',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      },
    ],
  },
  'phone-missed': {
    paths: [
      {
        d: 'M7.38028 9.85323C8.07627 11.3029 9.02506 12.6615 10.2266 13.8631C11.4282 15.0646 12.7869 16.0134 14.2365 16.7094C14.3612 16.7693 14.4235 16.7992 14.5024 16.8222C14.7828 16.904 15.127 16.8453 15.3644 16.6752C15.4313 16.6274 15.4884 16.5702 15.6027 16.4559C15.9523 16.1063 16.1271 15.9315 16.3029 15.8172C16.9658 15.3862 17.8204 15.3862 18.4833 15.8172C18.6591 15.9315 18.8339 16.1063 19.1835 16.4559L19.3783 16.6508C19.9098 17.1822 20.1755 17.448 20.3198 17.7333C20.6069 18.3009 20.6069 18.9712 20.3198 19.5387C20.1755 19.8241 19.9098 20.0898 19.3783 20.6213L19.2207 20.7789C18.6911 21.3085 18.4263 21.5734 18.0662 21.7756C17.6667 22 17.0462 22.1614 16.588 22.16C16.1751 22.1588 15.8928 22.0787 15.3284 21.9185C12.295 21.0575 9.43264 19.433 7.04466 17.0451C4.65668 14.6571 3.03221 11.7947 2.17124 8.76131C2.01103 8.19687 1.93092 7.91464 1.9297 7.5017C1.92833 7.04348 2.08969 6.42298 2.31411 6.02348C2.51636 5.66345 2.78117 5.39863 3.3108 4.86901L3.46843 4.71138C3.99987 4.17993 4.2656 3.91421 4.55098 3.76987C5.11854 3.4828 5.7888 3.4828 6.35636 3.76987C6.64174 3.91421 6.90746 4.17993 7.43891 4.71138L7.63378 4.90625C7.98338 5.25585 8.15819 5.43066 8.27247 5.60643C8.70347 6.26932 8.70347 7.1239 8.27247 7.78679C8.15819 7.96257 7.98338 8.13738 7.63378 8.48698C7.51947 8.60129 7.46231 8.65845 7.41447 8.72526C7.24446 8.96269 7.18576 9.30695 7.26748 9.58731C7.29048 9.6662 7.32041 9.72854 7.38028 9.85323Z',
        fill: '#C31212',
      },
      {
        d: 'M14.9961 4V9M14.9961 9L19.9961 9M14.9961 9L20.9961 3',
        stroke: '#C31212',
        strokeWidth: '1.5',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      },
    ],
  },
  'phone-outgoing': {
    paths: [
      {
        d: 'M7.38028 9.85323C8.07627 11.3029 9.02506 12.6615 10.2266 13.8631C11.4282 15.0646 12.7869 16.0134 14.2365 16.7094C14.3612 16.7693 14.4235 16.7992 14.5024 16.8222C14.7828 16.904 15.127 16.8453 15.3644 16.6752C15.4313 16.6274 15.4884 16.5702 15.6027 16.4559C15.9523 16.1063 16.1271 15.9315 16.3029 15.8172C16.9658 15.3862 17.8204 15.3862 18.4833 15.8172C18.6591 15.9315 18.8339 16.1063 19.1835 16.4559L19.3783 16.6508C19.9098 17.1822 20.1755 17.448 20.3198 17.7333C20.6069 18.3009 20.6069 18.9712 20.3198 19.5387C20.1755 19.8241 19.9098 20.0898 19.3783 20.6213L19.2207 20.7789C18.6911 21.3085 18.4263 21.5734 18.0662 21.7756C17.6667 22 17.0462 22.1614 16.588 22.16C16.1751 22.1588 15.8928 22.0787 15.3284 21.9185C12.295 21.0575 9.43264 19.433 7.04466 17.0451C4.65668 14.6571 3.03221 11.7947 2.17124 8.76131C2.01103 8.19687 1.93092 7.91464 1.9297 7.5017C1.92833 7.04348 2.08969 6.42298 2.31411 6.02348C2.51636 5.66345 2.78117 5.39863 3.3108 4.86901L3.46843 4.71138C3.99987 4.17993 4.2656 3.91421 4.55098 3.76987C5.11854 3.4828 5.7888 3.4828 6.35636 3.76987C6.64174 3.91421 6.90746 4.17993 7.43891 4.71138L7.63378 4.90625C7.98338 5.25585 8.15819 5.43066 8.27247 5.60643C8.70347 6.26932 8.70347 7.1239 8.27247 7.78679C8.15819 7.96257 7.98338 8.13738 7.63378 8.48698C7.51947 8.60129 7.46231 8.65845 7.41447 8.72526C7.24446 8.96269 7.18576 9.30695 7.26748 9.58731C7.29048 9.6662 7.32041 9.72854 7.38028 9.85323Z',
        fill: '#83BF6E',
      },
      {
        d: 'M20.9961 8V3M20.9961 3H15.9961M20.9961 3L14.9961 9',
        stroke: '#83BF6E',
        strokeWidth: '1.5',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      },
    ],
  },
  keypad: {
    paths: [
      {
        d: 'M8.5 4.5C8.5 5.32843 7.82843 6 7 6C6.17157 6 5.5 5.32843 5.5 4.5C5.5 3.67157 6.17157 3 7 3C7.82843 3 8.5 3.67157 8.5 4.5Z',
      },
      {
        d: 'M8.5 9.5C8.5 10.3284 7.82843 11 7 11C6.17157 11 5.5 10.3284 5.5 9.5C5.5 8.67157 6.17157 8 7 8C7.82843 8 8.5 8.67157 8.5 9.5Z',
      },
      {
        d: 'M8.5 14.5C8.5 15.3284 7.82843 16 7 16C6.17157 16 5.5 15.3284 5.5 14.5C5.5 13.6716 6.17157 13 7 13C7.82843 13 8.5 13.6716 8.5 14.5Z',
      },
      {
        d: 'M13.5 4.5C13.5 5.32843 12.8284 6 12 6C11.1716 6 10.5 5.32843 10.5 4.5C10.5 3.67157 11.1716 3 12 3C12.8284 3 13.5 3.67157 13.5 4.5Z',
      },
      {
        d: 'M13.5 9.5C13.5 10.3284 12.8284 11 12 11C11.1716 11 10.5 10.3284 10.5 9.5C10.5 8.67157 11.1716 8 12 8C12.8284 8 13.5 8.67157 13.5 9.5Z',
      },
      {
        d: 'M13.5 14.5C13.5 15.3284 12.8284 16 12 16C11.1716 16 10.5 15.3284 10.5 14.5C10.5 13.6716 11.1716 13 12 13C12.8284 13 13.5 13.6716 13.5 14.5Z',
      },
      {
        d: 'M13.5 19.5C13.5 20.3284 12.8284 21 12 21C11.1716 21 10.5 20.3284 10.5 19.5C10.5 18.6716 11.1716 18 12 18C12.8284 18 13.5 18.6716 13.5 19.5Z',
      },
      {
        d: 'M18.5 4.5C18.5 5.32843 17.8284 6 17 6C16.1716 6 15.5 5.32843 15.5 4.5C15.5 3.67157 16.1716 3 17 3C17.8284 3 18.5 3.67157 18.5 4.5Z',
      },
      {
        d: 'M18.5 9.5C18.5 10.3284 17.8284 11 17 11C16.1716 11 15.5 10.3284 15.5 9.5C15.5 8.67157 16.1716 8 17 8C17.8284 8 18.5 8.67157 18.5 9.5Z',
      },
      {
        d: 'M18.5 14.5C18.5 15.3284 17.8284 16 17 16C16.1716 16 15.5 15.3284 15.5 14.5C15.5 13.6716 16.1716 13 17 13C17.8284 13 18.5 13.6716 18.5 14.5Z',
      },
    ],
  },
};

const Icon = (props) => {
  const size = props.size ? props.size : 16;

  const renderIcon = () => {
    const iconData = icons[props.name];

    // Check if the icon is a complex (multi-path) icon
    if (iconData && typeof iconData === 'object' && iconData.paths) {
      return iconData.paths.map((pathProps, index) => (
        <path key={index} {...pathProps} />
      ));
    } else {
      // Single path icon (backward compatibility)
      return <path d={iconData} fill={props.fill || 'currentColor'}></path>;
    }
  };

  return (
    <svg
      onClick={(e) => {
        if (props.onClick) props.onClick(e);
      }}
      className={cn(props.className)}
      width={size}
      height={size}
      viewBox={props.viewBox ?? `0 0 ${16} ${16}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {renderIcon()}
    </svg>
  );
};

export default Icon;
